import { GoogleCookieProvider } from '@/config/cookies'
import ConsentGate from 'react-gdpr-cookie-consent/dist/components/ConsentGate'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export function CaptchaProvider({ children }: { children: React.ReactNode }) {
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={`${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`}
            language="de"
            scriptProps={{
                async: true,
                defer: true,
                appendTo: 'head'
            }}
            useRecaptchaNet
        >
            {children}
        </GoogleReCaptchaProvider>
    )
}

export function ConsentedCaptchaProvider({ children }: { children: React.ReactNode }) {
    return (
        <ConsentGate cookieProvider={GoogleCookieProvider}>
            <CaptchaProvider>{children}</CaptchaProvider>
        </ConsentGate>
    )
}
