import Mandatory from './Mandatory'

interface ILabel {
    htmlFor: string
    children: React.ReactNode
    mandatory?: boolean
}

export function Label({ htmlFor, children, mandatory = false, className, ...props }: ILabel & React.HTMLProps<HTMLLabelElement>) {
    return (
        <label htmlFor={htmlFor} {...props} className={`block text-xs text-gray-500 font-medium mb-1 whitespace-pre-wrap max-w-full relative pr-2 ${className}`}>
            {children} <Mandatory mandatory={mandatory} />
        </label>
    )
}
