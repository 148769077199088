import { CookieProvider } from 'react-gdpr-cookie-consent'

export const TebutoCookieProvider: CookieProvider = {
    name: 'Tebuto',
    id: 'tebuto_session',
    category: 'StrictlyNecessary',
    description: 'Wir verwenden Session-Cookies, um Ihre Sitzung auf unserer Website zu speichern. Dieses Cookie ist notwendig, um die Website zu nutzen.',
    provider: 'Tebuto',
    dataProtectionLink: 'https://tebuto.de/datenschutzerklaerung',
    cookiesSet: [
        {
            name: 'tebuto_app_session',
            duration: 7,
            unit: 'days',
            accessors: ['Tebuto'],
            purpose: 'Speichern der Sitzung'
        },
        {
            name: 'logged_in',
            duration: 7,
            unit: 'days',
            accessors: ['Tebuto'],
            purpose: 'Speichern des Login-Status'
        }
    ]
}

export const StripeCookieProvider: CookieProvider = {
    name: 'Stripe',
    id: 'stripe',
    category: 'Functional',
    description: 'Wir verwenden Stripe, um Zahlungen auf unserer Website abzuwickeln. Wenn Sie die App nutzen möchten, müssen Sie diesem Cookie zustimmen.',
    provider: 'Stripe',
    dataProtectionLink: 'https://stripe.com/de/privacy',
    cookiesSet: [
        {
            name: '__stripe_mid',
            duration: 1,
            unit: 'years',
            accessors: ['Stripe'],
            purpose: 'Erkennung und Verhinderung von Betrug'
        },
        {
            name: '__stripe_sid',
            duration: 1,
            unit: 'session',
            accessors: ['Stripe'],
            purpose: 'Erkennung und Verhinderung von Betrug'
        },
        {
            name: 'm',
            duration: 2,
            unit: 'years',
            accessors: ['Stripe'],
            purpose: 'Verwaltung von Einstellungen und Konfigurationen der Stripe-Integration'
        },
        {
            name: 'cid',
            duration: 1,
            unit: 'years',
            accessors: ['Stripe'],
            purpose: 'Speicherung der Kunden-ID für die Abwicklung von Zahlungen'
        },
        {
            name: 'ab_disable_remember_',
            duration: 7,
            unit: 'days',
            accessors: ['Stripe'],
            purpose: 'A/B-Tests zur Verbesserung der Nutzererfahrung'
        },
        {
            name: 'checkout_live_switcher',
            duration: 1,
            unit: 'session',
            accessors: ['Stripe'],
            purpose: 'Wechseln zwischen Test- und Live-Umgebung beim Checkout'
        },
        {
            name: 'ab_disable_remember_me',
            duration: 7,
            unit: 'days',
            accessors: ['Stripe'],
            purpose: 'Deaktivierung der "Remember Me"-Funktionalität während A/B-Tests'
        },
        {
            name: 'machine_identifier',
            duration: 1,
            unit: 'years',
            accessors: ['Stripe'],
            purpose: 'Erkennung und Verhinderung von Betrug'
        }
    ]
}

export const GoogleCookieProvider: CookieProvider = {
    name: 'Google',
    id: 'google',
    category: 'Functional',
    description:
        'Wir bieten unter anderem Google als Provider für den Login auf unserer Website an. Wenn Sie den Google Login verwenden möchten, müssen Sie diesem Cookie zustimmen.',
    provider: 'Google',
    dataProtectionLink: 'https://policies.google.com/privacy',
    cookiesSet: [
        {
            name: 'NID',
            duration: 6,
            unit: 'months',
            accessors: ['Google'],
            purpose: 'Speicherung von Benutzerpräferenzen und Verwaltung von Benutzersitzungen'
        },
        {
            name: '1P_JAR',
            duration: 1,
            unit: 'months',
            accessors: ['Google'],
            purpose: 'Sammlung von Website-Statistiken und Verfolgung von Conversion-Raten'
        },
        {
            name: 'SID',
            duration: 2,
            unit: 'years',
            accessors: ['Google'],
            purpose: 'Authentifizierung von Benutzern und Verhinderung betrügerischer Anmeldeinformationen'
        },
        {
            name: 'HSID',
            duration: 2,
            unit: 'years',
            accessors: ['Google'],
            purpose: 'Authentifizierung von Benutzern und Verhinderung betrügerischer Anmeldeinformationen'
        },
        {
            name: 'SSID',
            duration: 2,
            unit: 'years',
            accessors: ['Google'],
            purpose: 'Sicherheitsmaßnahmen zur Authentifizierung und Betrugsprävention'
        },
        {
            name: 'APISID',
            duration: 2,
            unit: 'years',
            accessors: ['Google'],
            purpose: 'Sicherheitsmaßnahmen zur Authentifizierung und Betrugsprävention'
        },
        {
            name: 'SAPISID',
            duration: 2,
            unit: 'years',
            accessors: ['Google'],
            purpose: 'Sicherheitsmaßnahmen zur Authentifizierung und Betrugsprävention'
        },
        {
            name: 'SIDCC',
            duration: 1,
            unit: 'years',
            accessors: ['Google'],
            purpose: 'Sicherheits-Cookie zum Schutz von Benutzerdaten'
        },
        {
            name: 'OGPC',
            duration: 2,
            unit: 'months',
            accessors: ['Google'],
            purpose: 'Speicherung von Benutzerpräferenzen und Informationen zur Nutzung von Google-Diensten'
        },
        {
            name: 'OGP',
            duration: 2,
            unit: 'months',
            accessors: ['Google'],
            purpose: 'Speicherung von Benutzerpräferenzen und Informationen zur Nutzung von Google-Diensten'
        },
        {
            name: '_GRECAPTCHA',
            duration: 6,
            unit: 'months',
            accessors: ['Google'],
            purpose: 'Risikoanalyse'
        }
    ]
}

export const LinkedInCookieProvider: CookieProvider = {
    name: 'LinkedIn',
    id: 'linkedin',
    category: 'Functional',
    description:
        'Wir bieten unter anderem LinkedIn als Provider für den Login auf unserer Website an. Wenn Sie den LinkedIn Login verwenden möchten, müssen Sie diesem Cookie zustimmen.',
    provider: 'LinkedIn',
    dataProtectionLink: 'https://www.linkedin.com/legal/privacy-policy',
    cookiesSet: [
        {
            name: 'bcookie',
            duration: 2,
            unit: 'years',
            accessors: ['LinkedIn'],
            purpose: 'Browser-ID-Cookie zur eindeutigen Identifikation von Geräten'
        },
        {
            name: 'bscookie',
            duration: 2,
            unit: 'years',
            accessors: ['LinkedIn'],
            purpose: 'Sicherheitscookie, um die Identität des Benutzers zu speichern'
        },
        {
            name: 'lidc',
            duration: 1,
            unit: 'days',
            accessors: ['LinkedIn'],
            purpose: 'Verwendung zur Routing-Optimierung'
        },
        {
            name: 'JSESSIONID',
            duration: 1,
            unit: 'session',
            accessors: ['LinkedIn'],
            purpose: 'Allgemeines Plattform-Sitzungs-Cookie, verwendet von Websites mit eingebetteten LinkedIn-Diensten'
        },
        {
            name: 'li_at',
            duration: 1,
            unit: 'years',
            accessors: ['LinkedIn'],
            purpose: 'Sitzungscookie, um Benutzer innerhalb der LinkedIn-Plattform zu authentifizieren'
        },
        {
            name: 'liap',
            duration: 3,
            unit: 'months',
            accessors: ['LinkedIn'],
            purpose: 'Wird für „Sichere Anmeldungen“ in anderen Diensten mit einem LinkedIn-Konto verwendet'
        },
        {
            name: 'UserMatchHistory',
            duration: 1,
            unit: 'months',
            accessors: ['LinkedIn'],
            purpose: 'Wird verwendet, um die ID eines LinkedIn-Mitglieds für die Werbeverfolgung zu synchronisieren'
        },
        {
            name: 'AnalyticsSyncHistory',
            duration: 1,
            unit: 'months',
            accessors: ['LinkedIn'],
            purpose: 'Speichert Informationen über die Zeit, zu der eine Synchronisierung mit dem lms_analytics-Cookie stattfand'
        },
        {
            name: 'lms_ads',
            duration: 1,
            unit: 'months',
            accessors: ['LinkedIn'],
            purpose: 'Wird verwendet, um die Relevanz von Anzeigen zu identifizieren und zu speichern'
        },
        {
            name: 'lms_analytics',
            duration: 1,
            unit: 'months',
            accessors: ['LinkedIn'],
            purpose: 'Wird verwendet, um Daten zur Analyse zu speichern'
        }
    ]
}
